import Axios from "axios";

const instance = Axios.create({
  baseURL: process.env.REACT_APP_DOMAIN
    ? process.env.REACT_APP_DOMAIN
    : "https://menual.azurewebsites.net",
});

instance.serializeJSON = (data) => {
  return Object.keys(data)
    .map(function (keyName) {
      return (
        encodeURIComponent(keyName) + "=" + encodeURIComponent(data[keyName])
      );
    })
    .join("&");
};

instance.spost = (url, data) => {
  return instance.post(url, instance.serializeJSON(data));
};

export default instance;
