import logo from "./logo.svg";
import "./App.css";

import React, { useEffect, useState } from "react";
import httpClient from "./httpClient";

let searchTimeout = null;
let nextWordTimeout = null;
let progressTimeout = null;

function App() {
  const useWords = ["SEARCH HERE...", "...חפש כאן", "BUSCA AQUI..."];
  let currentWord = 0;
  const [Placeholder, setPlaceholder] = useState(useWords[0]);
  const [PlaceholderBuilder, setPlaceholderBuilder] = useState("");
  const [SearchText, setSearchText] = useState("");
  const [Locations, setLocations] = useState([]);
  const [Loading, setLoading] = useState(false);

  const [AnimationVal, setAnimationVal] = useState(0);
  const nextPlaceholder = () => {
    currentWord++;
    if (currentWord >= useWords.length) {
      currentWord = 0;
    }
    setPlaceholderBuilder("");
    console.log("nextPlaceholder");
    setPlaceholder(useWords[currentWord]);
    if (nextWordTimeout) clearTimeout(nextWordTimeout);
    nextWordTimeout = setTimeout(nextPlaceholder, 4000);
  };
  useEffect(() => {
    nextPlaceholder();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (PlaceholderBuilder.length == Placeholder.length) {
        return;
      }
      setPlaceholderBuilder(
        Placeholder.substr(0, PlaceholderBuilder.length + 1)
      );
    }, 100);
    return () => {};
  }, [Placeholder, PlaceholderBuilder]);

  useEffect(() => {
    if (!SearchText) {
      return;
    }

    setLoading(true);
    if (searchTimeout) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      httpClient
        .spost("/ws/Location.asmx/GetLocationsFromQuery", {
          Name: SearchText,
        })
        .then(({ data }) => {
          console.log("GetLocationsFromQuery response", data);
          setLocations(data.Locations);
        })
        .catch(() => {})
        .then(() => setLoading(false));
    }, 1500);

    return () => {};
  }, [SearchText]);

  const updateProgress = () => {
    setAnimationVal((prevVal) => prevVal + 1);

    if (AnimationVal + 1 > 100) {
      setAnimationVal(0);
      return;
    }

    if (progressTimeout) {
      clearTimeout(progressTimeout);
    }
    progressTimeout = setTimeout(() => {
      updateProgress();
    }, 50);
  };

  useEffect(() => {
    if (Loading) {
      setAnimationVal(0);
      updateProgress();
    } else {
      setAnimationVal(0);
    }
    return () => {};
  }, [Loading]);

  return (
    <div
      className="container-fluid"
      style={{
        backgroundColor: "#fff",
        marginTop: 0,
        padding: 0,
        paddingLeft: 0,
        paddingRight: 0,
      }}
    >
      <div
        className="container-fluid"
        style={{
          backgroundColor: "#FCD755",
          backgroundImage:
            "url('/images/andrew-seaman-sQopSb2K0CU-unsplash.jpg')",
          backgroundSize: "cover",
          marginTop: 0,
          paddingTop: 0,
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <div className="container">
          <div
            className="col-12"
            style={{
              display: "flex",
              flex: 1,
              width: "100%",
              paddingTop: 20,
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img
              src="/images/menual_logo.webp"
              style={{
                width: 200,
                backgroundColor: "#FCD755",
                borderRadius: 10,
                padding: 5,
              }}
            />
            <div
              className="shadow-lg p-3 mb-3 mb-sm-5 bg-white rounded"
              style={{
                width: "100%",
                marginTop: 20,
              }}
            >
              <input
                style={{
                  border: "none",
                  width: "100%",
                }}
                className="search-input"
                type="text"
                onChange={(e) => setSearchText(e.target.value)}
                placeholder={PlaceholderBuilder}
              />
              {Loading && (
                <div
                  className="progress"
                  style={{
                    height: 5,
                  }}
                >
                  <div
                    className="progress-bar bg-success"
                    role="progressbar"
                    style={{
                      width: AnimationVal + "%",
                      height: 5,
                    }}
                    aria-valuenow={AnimationVal}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              )}
            </div>
          </div>
        </div>
        <style>
          {`
          input:focus, textarea:focus, select:focus{
            outline: none;
          }
          .search-input { 
            font-size : 30px;
          }

          @media (max-width: 600px){
            .search-input { 
              font-size : 18px;
            }
          }
        `}
        </style>
      </div>

      <style>
        {`
          .resta-pic {
            height : 100px;
            width : 100px;
          }

          .searching-bg {
              width : 30%;
              margin-left : 33%;
              margin-top:6%;
          }

          @media (max-width: 600px) {
            .resta-pic {
              height : 50px;
              width : 50px;
            }

            .searching-bg {
                width : 80%;
                margin-left : 10%;
                margin-top : 10%;
            }
          }
          `}
      </style>
      {Locations.length == 0 ? (
        <img className="searching-bg" src="/images/undraw_searching_p5ux.svg" />
      ) : (
        <div
          className="container shadow-lg rounded"
          style={{
            paddingTop: 10,
            marginTop: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: 20,
          }}
        >
          <div
            className="row"
            style={{
              width: "100%",
              margin: 0,
              padding: 0,
            }}
          >
            <div
              className="col-12"
              style={{
                padding: 0,
              }}
            >
              {Locations.map((location, key) => {
                return (
                  <div
                    key={key}
                    className="row shadow-sm p-1 p-sm-3 bg-white rounded"
                    style={{
                      // width: "100%",
                      marginLeft: 2,
                      marginRight: 2,
                      marginTop: 10,
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "0.5px solid #ededed",
                    }}
                  >
                    <div
                      className="col-12 col-md-6"
                      style={{
                        display: "flex",
                        paddingLeft: 5,
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="rounded-circle resta-pic"
                        style={{
                          backgroundImage: `url(${httpClient.defaults.baseURL.replace(
                            "menualdev",
                            "menual"
                          )}/firstpageimages/${location.ID}.jpg)`,
                          backgroundSize: "cover",
                        }}
                      ></div>
                      <h4
                        style={{
                          marginLeft: 10,
                          marginRight: 10,
                          display: "flex",
                          flex: 1,
                        }}
                      >
                        {location.Name}
                      </h4>
                    </div>
                    <div
                      className=" col-12 col-md-6 d-none d-sm-block"
                      style={{
                        marginLeft: 0,
                        paddingLeft: 0,
                      }}
                    >
                      <div
                        className="col-12"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          margin: 0,
                          paddingLeft: 0,
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <a
                          target="_blank"
                          href={`${httpClient.defaults.baseURL}/menu/?id=${location.TableID}`}
                          className="btn btn-warning btn-lg"
                        >
                          Menu
                        </a>
                        <a
                          target="_blank"
                          href={`${httpClient.defaults.baseURL}/menu/?id=${location.TableID}&takeout=true`}
                          className="btn btn-warning btn-lg"
                        >
                          Takeout Menu
                        </a>
                        <a
                          target="_blank"
                          href={`${httpClient.defaults.baseURL}/menu/?id=${location.TableID}&delivery=true`}
                          className="btn btn-warning btn-lg"
                        >
                          Delivery Menu
                        </a>
                      </div>
                    </div>
                    <div
                      className="col-12 col-md-6 d-block d-sm-none"
                      style={{
                        paddingRight: 0,
                        marginTop: 10,
                        paddingLeft: 5,
                        paddingRight: 5,
                        paddingBottom: 5,
                      }}
                    >
                      <div
                        className=""
                        style={{
                          display: "flex",
                          width: "100%",

                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <a
                          target="_blank"
                          href={`${httpClient.defaults.baseURL}/menu/?id=${location.TableID}`}
                          className="btn btn-warning "
                        >
                          Menu
                        </a>
                        <a
                          target="_blank"
                          href={`${httpClient.defaults.baseURL}/menu/?id=${location.TableID}&takeout=true`}
                          className="btn btn-warning "
                        >
                          Takeout
                        </a>
                        <a
                          target="_blank"
                          href={`${httpClient.defaults.baseURL}/menu/?id=${location.TableID}&delivery=true`}
                          className="btn btn-warning "
                        >
                          Delivery
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
